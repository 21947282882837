<template>
  <section class="calendar-view w-full min-h-0 flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="w-full h-full flex flex-col">
      <calendar-header :discipline-id="disciplineId" @set-discipline-id="disciplineId = $event" />
      <div
        class="calendar-main w-full min-h-0 flex flex-col flex-grow px-8 py-4 space-x-8 relative"
      >
        <app-scrollbar
          class="calendar-scrollbar justify-center items-center space-y-5 pr-4"
          width="100%"
          height="100%"
          scroll="y"
          slide="y"
        >
          <tournament-row
            v-for="(tournament, i) in tournaments"
            :key="i"
            :tournament="tournament"
            :index="i"
            @reload-data="loadState(true)"
          />
        </app-scrollbar>
      </div>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import CalendarHeader from './CalendarHeader.vue'
import TournamentRow from '../TournamentRow.vue'
import { useTournamentsCalendarStore } from '@/store/pinia/tournaments/useTournamentsCalendarStore'
import type { Tournament } from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

interface ComponentData {
  disciplineId: number | 'all'
  isLoading: boolean
}

export default defineComponent({
  components: {
    CalendarHeader,
    TournamentRow,
  },
  data(): ComponentData {
    return {
      disciplineId: 'all',
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsCalendarStore, { _tournaments: 'tournaments' }),
    tournaments(): Tournament[] {
      const date = Date.now()

      if (!this._tournaments) return []

      const tournaments = [...this._tournaments]
        .sort(
          (a: Tournament, b: Tournament): number =>
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
        )
        .filter(({ end_date }: Tournament): boolean => new Date(end_date).getTime() > date)
        .slice(0, 10)

      if (this.disciplineId === 'all') return tournaments

      return tournaments.filter(
        ({ discipline_id }: Tournament): boolean => discipline_id === this.disciplineId,
      )
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadState(true)
    this.isLoading = false
  },
  methods: {
    ...mapActions(useTournamentsCalendarStore, ['loadState']),
  },
})
</script>

<style lang="scss" scoped>
.calendar-view {
  .calendar-main {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 5rem;
      background-image: linear-gradient(0deg, rgba(2, 20, 34, 0.7) 40%, rgba(35, 52, 65, 0) 100%);
    }
  }
}
</style>
