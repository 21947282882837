<template>
  <div v-if="progress" class="progress-bar-wrapper flex flex-row justify-between items-center">
    <p class="text-texts-standard-default text-36 uppercase mr-6">
      {{ $t('tournaments.nextMilestone') }}
    </p>
    <div class="progress-bar">
      <p
        class="progress-bar-left flexing text-texts-standard-default text-50 absolute top-3 z-1"
        :class="{ 'is-repeatable': progress.repeatable }"
      >
        <template v-if="!progress.repeatable">
          {{ progress.milestone }}
        </template>
      </p>
      <div class="progress-bar-bar flex flex-row justify-start items-center relative">
        <div
          class="progress-bar-bar-fill relative"
          :style="{ width: `${currentProgress * 100}%` }"
        />
        <div class="progress-bar-bar-info flexing space-x-2 absolute z-1">
          <p class="progress-bar-bar-info-state text-texts-standard-default text-28">
            {{ `${currentPointsFixed} / ${nextPointsFixed}` }}
          </p>
          <app-main-icon
            :icon-size="32"
            :icon-name="TOURNAMENT_POINTS"
            :tooltip="TOURNAMENT_POINTS"
          />
        </div>
      </div>
      <p
        class="progress-bar-right flexing text-texts-standard-default text-50 absolute top-3 z-1"
        :class="{ 'is-repeatable': progress.repeatable }"
      >
        <template v-if="!progress.repeatable">
          {{ progress.next_milestone }}
        </template>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { TOURNAMENT_POINTS } from '@/globalVariables'
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

interface ComponentData {
  TOURNAMENT_POINTS: typeof TOURNAMENT_POINTS
}

export default defineComponent({
  data(): ComponentData {
    return {
      TOURNAMENT_POINTS,
    }
  },
  computed: {
    ...mapState(useTournamentsMilestonesStore, ['progress', 'milestones']),
    previousPoints(): number {
      return this.milestones?.[this.progress?.milestone ?? 0]?.points ?? 0
    },
    currentPoints(): number {
      return this.progress?.points ?? 0
    },
    nextPoints(): number {
      return this.progress?.next_points ?? 0
    },
    currentProgress(): number {
      if (!this.previousPoints || !this.currentPoints || !this.nextPoints) return 0

      return this.currentPointsFixed / this.nextPointsFixed
    },
    currentPointsFixed(): number {
      const current = this.currentPoints - this.previousPoints

      return current < 0 ? this.currentPoints : current
    },
    nextPointsFixed(): number {
      const total = this.nextPoints - this.previousPoints

      return total < 0 ? this.nextPoints : total
    },
  },
})
</script>

<style lang="scss" scoped>
.progress-bar {
  position: relative;
  margin-left: 3.25rem;
  margin-right: 3.25rem;

  &-left,
  &-right {
    width: 6rem;
    height: 6rem;
    @include background(
      url('#{$path-images}/tournaments/milestones/milestone-default.avif'),
      contain
    );
  }

  &-left {
    left: -3.75rem;

    &.is-repeatable {
      background-image: url('#{$path-images}/tournaments/milestones/progress-start-bg.avif?v=100');
      width: 1.875rem;
      height: 7.5rem;
      top: 0;
      left: -1.875rem;
    }
  }

  &-right {
    right: -3.75rem;

    &.is-repeatable {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 0.125rem), -50%) scale(0.75);
        width: 3.625rem;
        height: 3.688rem;
        @include background(url('#{$path-images}/tournaments/milestones/ico-repeat.avif'), contain);
      }
    }
  }

  &-bar {
    width: 30rem;
    height: 7.5rem;
    @include background(
      url('#{$path-images}/tournaments/milestones/progress-bg.avif?v=100'),
      contain,
      center,
      repeat-x
    );

    &-info {
      left: 50%;
      transform: translateX(-50%);
    }

    &-fill {
      height: 7.5rem;
      @include background(
        url('#{$path-images}/tournaments/milestones/progress-fill-bg.avif?v=100'),
        contain,
        center,
        repeat-x
      );

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 0.625rem;
        height: 7.5rem;
        @include background(
          url('#{$path-images}/tournaments/milestones/progress-fill-end-bg.avif?v=100'),
          contain
        );
      }
    }
  }
}
</style>
