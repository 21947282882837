<template>
  <div>
    <app-icon icon-name="question-70" @click="isOpen = true" />
    <info-popup
      v-if="isOpen"
      :popup-title="$t('tournaments.tournaments')"
      class="tournaments-info-popup"
      width="76rem"
      @close="isOpen = false"
    >
      <app-scrollbar
        class="tournaments-info-popup-scrollbar space-y-5 pr-4"
        width="100%"
        height="41.5rem"
        scroll="y"
        slide="y"
      >
        <div
          class="tournaments-info-popup-content w-full flex flex-col text-32 text-texts-standard-default"
        >
          <p class="tournaments-info-popup-content-header text-left font-bold">{{ title }}:</p>
          <p
            v-for="i in indices"
            :key="i"
            class="tournaments-info-popup-content-text text-left relative"
          >
            {{
              i === 10
                ? $replacePlaceholder(
                    $t(`tournaments.tournamentInfo${i}`),
                    '{pass}',
                    $t('tournaments.tournamentPass'),
                  )
                : $t(`tournaments.tournamentInfo${i}`)
            }}
          </p>
        </div>
      </app-scrollbar>
    </info-popup>
  </div>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { defineComponent, type PropType } from 'vue'

export enum TournamentInfoButtonContext {
  Tournaments,
  Milestones,
}

interface ComponentData {
  isOpen: boolean
}

export default defineComponent({
  components: {
    InfoPopup,
  },
  props: {
    context: {
      type: Number as PropType<TournamentInfoButtonContext>,
      default: TournamentInfoButtonContext.Tournaments,
    },
  },
  data(): ComponentData {
    return {
      isOpen: false,
    }
  },
  computed: {
    title(): string {
      if (this.context === TournamentInfoButtonContext.Tournaments)
        return this.$t('tournaments.basicInfo')
      if (this.context === TournamentInfoButtonContext.Milestones)
        return this.$t('tournaments.milestones')
      return ''
    },
    indices(): number[] {
      if (this.context === TournamentInfoButtonContext.Tournaments)
        return Array.from({ length: 7 }, (_: undefined, i: number): number => i + 1)
      return Array.from({ length: 4 }, (_: undefined, i: number): number => i + 1 + 7)
    },
  },
})
</script>

<style lang="scss" scoped>
.tournaments-info-popup {
  &-content {
    padding: 2rem 4.5rem 1rem;

    &-text {
      padding-right: 1.625rem;
      margin: 0.5rem 0;

      &::before {
        content: '';
        position: absolute;
        left: -2rem;
        top: 1.15rem;
        width: 0.625rem;
        height: 0.625rem;
        background-color: #fff;
      }
    }
  }
}
</style>
