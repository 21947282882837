<template>
  <div
    class="calendar-row w-full flex flex-row justify-between items-center space-x-3"
    :data-tournament-id="tournament.id"
  >
    <div
      class="left-side h-full flexing flex-grow"
      :class="[
        `is-tournament-${tournament.tournament_state}`,
        `is-user-${tournament.user_state}`,
        index % 2 === 0 ? 'is-even' : 'is-odd',
        { 'is-locked relative': isLocked },
      ]"
    >
      <div
        v-if="isLocked"
        class="locked-overlay flex flex-row justify-start items-center absolute inset-0 z-1"
      >
        <div class="px-12 py-5">
          <app-icon icon-name="lock-lg" class="pointer-events-none" />
        </div>
      </div>
      <div class="p-5">
        <app-discipline-icon :discipline-id="tournament.discipline_id" :size="120" theme="light" />
      </div>
      <div class="info flex flex-col justify-center items-start p-5">
        <p class="text-32 text-texts-standard-default font-semibold uppercase truncate">
          {{ tournament.name }}
        </p>
        <p class="text-36 text-texts-standard-important font-bold uppercase">
          {{ $translateDiscipline(tournament.discipline_id) }}
        </p>
        <p class="text-32 text-texts-standard-default font-normal">
          {{ $t('tournaments.attribute') }}: {{ tournament.attribute }}
        </p>
      </div>
      <div class="flex flex-col justify-center items-center space-y-4 ml-auto">
        <p class="text-32 text-texts-standard-default font-semibold uppercase">
          {{
            tournament.tournament_state === TournamentState.Finished
              ? $t('tournaments.yourRewards')
              : $t('tournaments.rewardForVictory')
          }}
        </p>
        <rewards
          reward-id="rewards"
          :reward-data="tournament.rewards"
          :icon-size="48"
          modifier-tag="tournaments"
        />
      </div>
      <div
        class="detail-button h-full flex flex-col justify-center items-center cursor-pointer"
        :class="{
          'is-locked': isLocked,
        }"
        @click="
          $router.push({ name: $getWebView('TournamentsDetail'), params: { id: tournament.id } })
        "
      >
        <div v-if="!isLocked" class="detail-button-icon" />
      </div>
    </div>
    <div
      class="right-side h-full flex flex-col justify-evenly items-center"
      :class="`is-tournament-${tournament.tournament_state} is-user-${tournament.user_state}`"
    >
      <p v-if="isRunning">
        <app-timer
          :time="remainingTimeToEnd"
          class="flexing"
          @countdown-action="$emit('reloadData')"
        />
      </p>
      <p v-else class="time flexing text-texts-standard-default text-32 pt-1">
        <template v-if="isFinished">
          {{ getFormattedDateFromYMD(tournament.start_date) }}
          <span class="text-texts-standard-important ml-2">
            {{ getFormattedTimeFromHMS(tournament.start_date) }}
          </span>
        </template>
        <span v-else class="text-texts-standard-important font-bold">
          {{ getFormattedTimeFromHMS(tournament.start_date) }}
        </span>
      </p>
      <p v-if="isJoined" class="flexing text-40 text-texts-standard-positive font-bold uppercase">
        {{ $t('tournaments.signedUp') }}
      </p>
      <p
        v-else-if="isFinished && tournament.position"
        class="position flexing text-36 text-texts-standard-default uppercase"
        :class="`is-position-${tournament.position}`"
      >
        <span class="ico-flag mr-2" />
        <span
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder($t('tournaments.rankX'), '%s.', '{b} %s. {/b}'),
                'b',
                'font-bold',
              ),
              '%s',
              tournament.position,
            )
          "
        ></span>
      </p>
      <p
        v-else-if="isLocked"
        class="flexing text-32 font-medium text-texts-standard-default uppercase"
      >
        {{ $t('tournaments.disciplineLocked') }}
        <tippy theme="WSM" placement="top" max-width="35rem" class="ml-4">
          <app-icon icon-name="info-44" />
          <template #content>
            <div class="tooltip-content text-texts-standard-default text-30">
              <p
                v-html="
                  $replacePlaceholder(
                    $replaceHtmlPlaceholders(
                      $replacePlaceholder($t('tournaments.reachLevelX'), '%s', '{b} %s {/b}'),
                      'b',
                      'text-texts-standard-important',
                    ),
                    '%s',
                    disciplineUnlockLevel,
                  )
                "
              />
            </div>
          </template>
        </tippy>
      </p>
      <app-button
        v-else
        :btn-id="isRunningAndCanPlay ? 'enter-button-exclamation' : undefined"
        :class="isRunningAndCanPlay ? 'enter-button' : 'join-button'"
        btn-size="md"
        :btn-type="isRunningAndCanPlay ? 'secondary' : 'confirm'"
        @click="
          $router.push({ name: $getWebView('TournamentsDetail'), params: { id: tournament.id } })
        "
      >
        {{
          isRunningAndCanPlay
            ? $t('tournaments.enter')
            : isRunning
              ? $t('tournaments.join')
              : $t('tournaments.signUp')
        }}
      </app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { getFormattedTimeFromHMS, getFormattedDateFromYMD } from '@/helpers'
import Rewards from '@/components/Rewards.vue'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import {
  TournamentState,
  TournamentUserState,
  type Tournament,
} from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'

interface ComponentData {
  TournamentState: typeof TournamentState
}

export default defineComponent({
  components: {
    Rewards,
  },
  props: {
    tournament: {
      type: Object as PropType<Tournament>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['reloadData'],
  data: (): ComponentData => ({
    TournamentState,
  }),
  computed: {
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    isJoined(): boolean {
      return (
        this.tournament.tournament_state === TournamentState.Open &&
        this.tournament.user_state === TournamentUserState.Joined
      )
    },
    isRunning(): boolean {
      return this.tournament.tournament_state === TournamentState.Running
    },
    isRunningAndCanPlay(): boolean {
      return this.isRunning && this.tournament.user_state === TournamentUserState.Running
    },
    isFinished(): boolean {
      return this.tournament.tournament_state === TournamentState.Finished
    },
    isLocked(): boolean {
      return (
        this.tournament.user_state === TournamentUserState.Locked ||
        this.disciplineUnlockLevel !== null
      )
    },
    remainingTimeToStart(): number {
      return this.tournament.start_date
        ? (new Date(this.tournament.start_date).getTime() - Date.now()) / 1e3
        : 0
    },
    remainingTimeToEnd(): number {
      return this.tournament.end_date
        ? (new Date(this.tournament.end_date).getTime() - Date.now()) / 1e3
        : 0
    },
    disciplineUnlockLevel(): number {
      return this.disciplinesMenuData?.find(
        (level: DisciplineMenuData): boolean => level.id === this.tournament.discipline_id,
      )?.levelToUnlock
    },
  },
  methods: {
    getFormattedTimeFromHMS,
    getFormattedDateFromYMD,
  },
})
</script>

<style lang="scss" scoped>
.calendar-row {
  height: 10rem;

  .left-side {
    width: 88.625rem;
    border: solid 0.125rem #7477a4;

    &.is-even {
      @if $isSsm {
        background-color: rgba(82, 84, 120, 0.7);
      }
      @if $isWsm {
        background-color: #417491;
        border-color: #5883a3;
      }
    }

    &.is-odd {
      @if $isSsm {
        background-color: rgba(114, 114, 176, 0.7);
      }
      @if $isWsm {
        background-color: #236d9b;
        border-color: #6c9ec4;
      }
    }

    &.is-tournament-open.is-user-joined {
      @include background(
        url('#{$path-images}/tournaments/calendar/joined-bg.avif'),
        contain,
        left
      );
      @if $isSsm {
        border-color: #0ee7cc;
      }
      @if $isWsm {
        border-color: #3fe443;
      }
    }

    &.is-tournament-running {
      @include background(
        url('#{$path-images}/tournaments/calendar/running-bg.avif'),
        contain,
        left
      );
      @if $isSsm {
        border-color: #feb942;
      }
      @if $isWsm {
        border-color: #fadd1e;
      }
    }

    &.is-user-locked,
    &.is-locked {
      border: 0;
      .locked-overlay {
        background-color: rgba(47, 56, 87, 0.8);
        @if $isWsm {
          background-color: rgba($color: #152335, $alpha: 0.8);
        }
      }
    }

    .info {
      width: 29.375rem;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      line-height: 1.25;

      p {
        max-width: 100%;
      }
    }

    .rewards {
      min-width: 45.625rem;
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
        border-image-source: linear-gradient(
          to right,
          transparent,
          rgba(255, 255, 255, 0.5),
          transparent
        );
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #6c9ec480, $alpha: 0.5),
          transparent
        );
        border-image-source: linear-gradient(
          to right,
          transparent,
          rgba($color: #ffffff80, $alpha: 0.5),
          transparent
        );
      }
    }

    .detail-button {
      width: 3.625rem;

      &:not(.is-locked) {
        @if $isSsm {
          background-color: #2f3857;
        }
        @if $isWsm {
          background-color: #0d2038;
        }
      }

      &-icon {
        width: 1.5rem;
        height: 2.813rem;
        @include background(url('#{$path-images}/tournaments/arrow-forward.avif'), contain);
      }
    }
  }

  .right-side {
    width: 26.125rem;
    @if $isSsm {
      background-color: rgba(47, 56, 87, 0.7);
      border: solid 0.125rem #7477a4;
    }
    @if $isWsm {
      background-color: #1b2f47;
      border: solid 0.125rem #6c9ec4;
    }

    &.is-tournament-open.is-user-joined {
      @if $isSsm {
        border-color: #0ee7cc;
      }
      @if $isWsm {
        border-color: #3fe443;
      }

      .time {
        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(14, 231, 204, 0.5),
            transparent
          );
        }
        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba($color: #3fe44380, $alpha: 0.5),
            transparent
          );
        }
      }
    }

    &.is-tournament-running {
      @include background(url('#{$path-images}/tournaments/calendar/running-small-bg.avif'), cover);
      @if $isSsm {
        border-width: 0.25rem;
        border-color: #feb942;
      }
      @if $isWsm {
        border-width: 0.25rem;
        border-color: #fadd1e;
      }
    }

    .time {
      width: 25.875rem;
      height: 3.375rem;
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #6c9ec480, $alpha: 0.5),
          transparent
        );
      }
    }

    .enter-button,
    .join-button {
      width: 24.625rem;
    }

    .position {
      width: 25.875rem;
      height: 3.563rem;
      @include background(url('#{$path-images}/tournaments/calendar/pos-bg.avif'), contain);

      &.is-position-1 {
        @include background(url('#{$path-images}/tournaments/calendar/pos-1-bg.avif'), contain);
      }

      &.is-position-2 {
        @include background(url('#{$path-images}/tournaments/calendar/pos-2-bg.avif'), contain);
      }

      &.is-position-3 {
        @include background(url('#{$path-images}/tournaments/calendar/pos-3-bg.avif'), contain);
      }

      .ico-flag {
        width: 4.438rem;
        height: 2.625rem;
        @include background(url('#{$path-images}/tournaments/calendar/ico-flag.avif'), contain);
      }
    }
  }
}
</style>
