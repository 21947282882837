import { tournamentsActiveStateEndpoint as TOURNAMENTS_ACTIVE_STATE_ENDPOINT } from '@/globalVariables'
import type { TournamentsActiveStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsActiveStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentsActiveStore {
  state: TournamentsActiveStateApiResponse[] | null
}

export const useTournamentsActiveStore = defineStore('tournamentsActiveStore', {
  state: (): TournamentsActiveStore => ({
    state: null,
  }),
  getters: {
    activeTournaments(): TournamentsActiveStateApiResponse[] | null {
      return this.state ?? null
    },
  },
  actions: {
    async loadState(force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsActiveStateApiResponse[]>(
          TOURNAMENTS_ACTIVE_STATE_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
