<template>
  <section class="milestones-view w-full flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="flex flex-col flex-grow">
      <milestones-header />
      <milestones-progress />
      <individual-milestones-main />
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import MilestonesHeader from './MilestonesHeader.vue'
import MilestonesProgress from './MilestonesProgress.vue'
import IndividualMilestonesMain from './Individual/MilestonesMain.vue'
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'

interface ComponentData {
  isLoading: boolean
}

export default defineComponent({
  components: {
    MilestonesHeader,
    MilestonesProgress,
    IndividualMilestonesMain,
  },
  data(): ComponentData {
    return {
      isLoading: false,
    }
  },
  async created(): Promise<void> {
    this.isLoading = true
    await Promise.all([this.loadConfig(), this.loadState(true)])
    this.isLoading = false
  },
  methods: {
    ...mapActions(useTournamentsMilestonesStore, ['loadConfig', 'loadState']),
  },
})
</script>

<style lang="scss" scoped></style>
