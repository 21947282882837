import type Reward from '@/interfaces/Reward'

export enum TournamentState {
  Open = 'open',
  Running = 'running',
  Finished = 'finished',
  Cancelled = 'cancelled',
}

export enum TournamentUserState {
  Joined = 'joined',
  Running = 'running',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Locked = 'locked',
}

export interface Tournament {
  id: number
  discipline_id: number
  name: string
  attribute: number
  start_date: string
  end_date: string
  benefit: boolean
  rewards: Reward[]
  tournament_state: TournamentState
  user_state: TournamentUserState | null
  position: number | null
  play_with_mulligans: boolean
  rounds_total: number
}

export interface TournamentsCalendarStateApiResponse {
  free_daily_joins_limit: number
  free_daily_joins: number
  join_gems_price: number
  join_starts_price: number
  play_mulligans_price: number
  tournaments: Tournament[]
  tournament_points: number
  mulligans: number
}
