import { tournamentsRankingsStateEndpoint as TOURNAMENTS_RANKINGS_STATE_ENDPOINT } from '@/globalVariables'
import type { TournamentsRankingsStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsRankingsStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface StateParams {
  type: string
  period: string
}

interface StatePayload {
  type: string
  season: string
}

interface TournamentsRankingsStore {
  state: TournamentsRankingsStateApiResponse | null
}

export const useTournamentsRankingsStore = defineStore('tournamentsRankingsStore', {
  state: (): TournamentsRankingsStore => ({
    state: null,
  }),
  getters: {
    rankings(): TournamentsRankingsStateApiResponse['ranking'] | null {
      return this.state?.ranking ?? null
    },
  },
  actions: {
    async loadState(params: StateParams, force: boolean = false): Promise<void> {
      if (this.state && !force) return

      try {
        const response = await ApiService.post<TournamentsRankingsStateApiResponse, StatePayload>(
          TOURNAMENTS_RANKINGS_STATE_ENDPOINT,
          {
            type: params.type,
            season: params.period,
          },
          { force: true },
        )

        if (response.ranking) {
          Object.keys(response.ranking).forEach((key: string): void => {
            response.ranking[key].tournament_titles = response.ranking[key].titles
          })
        }

        this.state = response
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
