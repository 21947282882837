<template>
  <div v-if="!isContentLoading" class="table-standard">
    <section
      v-for="(row, index) in rows"
      :key="index"
      class="table-row-content text-texts-standard-default table-standard-row table-standard text-32 flex items-center"
      :class="[
        userData?.id === row.id || (userData.club_stats?.id === row.clubId && highlightClubMembers)
          ? 'my-row'
          : '',
        table === 'records' ? '' : 'justify-between',
      ]"
      :style="{ width }"
    >
      <div
        class="table-row-cell table-row-position font-bold flexing h-full text-36"
        :class="{ 'text-texts-standard-dark': index < 3 || $isSsm }"
      >
        {{ index + 1 }}
      </div>
      <div
        v-if="!hiddenColumns.includes('name')"
        class="table-row-cell flex items-center h-full"
        :class="[table === 'clubLeaderboard' ? 'table-row-clubName' : 'table-row-player']"
        :style="isClubActivityTable ? { 'flex-grow': 2 } : {}"
      >
        <app-club-name
          v-if="table === 'clubLeaderboard'"
          :club-id="row.clubId"
          :name="row.club ?? row.name"
          :club-logo-id="row.clubLogoId"
          :clubs-background-id="row.clubLogoBgId"
        />

        <app-user
          v-else
          :id="row.id || ''"
          :country="row.country"
          :name="row.name"
          :is-bot="row.isBot"
          :badges-data="row.eventBadges ?? null"
        />
      </div>
      <div
        v-if="
          [RankingType.Global, RankingType.TournamentPoints, RankingType.TournamentTitles].includes(
            table,
          )
        "
        class="global-table h-full flex items-center"
      >
        <div class="table-row-cell global-table-level h-full flexing">
          {{ row.level }}
        </div>
        <div class="table-row-cell global-table-club h-full flexing">
          <app-club-name
            :club-id="row.club_id ?? row.clubId"
            :name="row.club"
            :club-logo-id="row.clubLogoId"
            :clubs-background-id="row.clubLogoBgId"
          />
        </div>
        <div class="table-row-cell global-table-points h-full flexing">
          <template v-if="isTournamentPointsTable">
            <p>{{ $filters.$formatNumber(row.tournament_points) }}</p>
            <app-main-icon
              :icon-size="48"
              :icon-name="TOURNAMENT_POINTS"
              :tooltip="TOURNAMENT_POINTS"
            />
          </template>
          <template v-else-if="isTournamentTitlesTable">
            <p>{{ $filters.$formatNumber(row.tournament_titles) }}</p>
            <app-main-icon
              :icon-size="48"
              :icon-name="TOURNAMENT_TITLES"
              :tooltip="TOURNAMENT_TITLES"
            />
          </template>
          <template v-else>
            <p>{{ row.points }}</p>
            <app-main-icon icon-name="ranking_points" :icon-size="48" />
          </template>
        </div>
      </div>
      <div v-if="table === 'discipline'" class="discipline-table h-full flex items-center">
        <div class="table-row-cell discipline-table-level" :class="{ 'no-rewards': hideRewards }">
          {{ row.level }}
        </div>
        <div
          class="table-row-cell discipline-table-club flexing pr-4"
          :class="{ 'no-rewards': hideRewards }"
        >
          <app-club-name
            :club-id="row.club_id ?? row.clubId"
            :name="row.club"
            :club-logo-id="row.clubLogoId"
            :clubs-background-id="row.clubLogoBgId"
          />
        </div>
        <div class="table-row-cell discipline-table-points">
          {{ row.points }}
        </div>
        <div v-if="!hideRewards" class="table-row-cell discipline-table-rewards font-bold flexing">
          <div
            v-for="(rewardObject, indexRew) in row.rewards"
            :key="indexRew"
            class="table-row-cell-reward flexing"
          >
            <p>{{ rewardObject.value ?? '' }}</p>
            <app-main-icon
              :icon-size="48"
              :icon-name="
                rewardObject.rarity
                  ? rewardObject.type + '-' + rewardObject.rarity
                  : rewardObject.type
              "
              :tooltip="
                rewardObject.rarity
                  ? rewardObject.type + capitalize(rewardObject.rarity)
                  : rewardObject.type
              "
            />
          </div>
        </div>
      </div>
      <div v-if="table === 'records'" class="records-table h-full flex items-center">
        <div class="table-row-cell records-table-level">
          {{ row.level }}
        </div>
        <div class="table-row-cell records-table-club flexing pr-4">
          <app-club-name
            :club-id="row.club_id ?? row.clubId"
            :name="row.club"
            :club-logo-id="row.clubLogoId"
            :clubs-background-id="row.clubLogoBgId"
          />
        </div>
        <div
          class="table-row-cell records-table-season flexing text-texts-standard-important font-bold"
        >
          {{ row.season }}
        </div>
        <div class="table-row-cell records-table-result font-bold flexing">
          {{ formatResult(row.result, row.discipline_id) }}
        </div>
      </div>
      <div v-if="isClubActivityTable" class="club-activity-table h-full flex items-center">
        <div class="table-row-cell global-table-points h-full flexing">
          <p>{{ row.points }}</p>
          <app-main-icon :icon-name="row.type" :icon-size="48" />
        </div>
      </div>
      <div v-if="table == 'clubLeaderboard'" class="records-table h-full flex items-center">
        <div class="table-row-cell global-table-members">
          {{ row.members }}
        </div>
        <div class="table-row-cell global-table-points h-full flexing">
          <p>{{ row.points }}</p>
          <app-main-icon :icon-name="row.type" :icon-size="48" />
        </div>
      </div>
      <template v-if="isTournamentResultsTable && row?.rounds">
        <div
          v-for="(round, i) in row.rounds"
          :key="i"
          class="table-row-cell tournament-table-round flexing"
          :class="{ 'is-wide': row?.rounds?.length === 2 }"
        >
          {{ round.result ? formatResult(round.result, row.discipline_id) : '-' }}
        </div>
      </template>
      <div
        v-if="isTournamentResultsTable && !hiddenColumns.includes('result')"
        class="table-row-cell tournament-table-result flexing"
      >
        {{ row.result ? formatResult(row.result, row.discipline_id) : '-' }}
      </div>
      <div
        v-if="isTournamentResultsTable"
        class="table-row-cell tournament-table-rewards flexing"
        :class="{ 'is-wide': hiddenColumns.length === 2, 'is-narrow': rewardsInfoButton }"
      >
        <tippy
          v-if="rewardsInfoButton"
          class="table-row-cell-reward"
          theme="WSM1"
          placement="top"
          max-width="auto"
        >
          <app-icon icon-name="info-50" />
          <template #content>
            <div class="lock-tooltip">
              <div class="lock-tooltip-title flexing">
                <p class="uppercase font-bold text-30 pt-2">
                  {{ $replacePlaceholder($t('tournaments.rankX'), '%s', (index + 1).toString()) }}
                </p>
              </div>
              <div class="flexing p-8 space-x-8">
                <div
                  v-for="(rewardObject, indexRew) in row.rewards"
                  :key="indexRew"
                  class="flexing"
                >
                  <p>{{ rewardObject.value ?? '' }}</p>
                  <app-main-icon
                    :icon-size="48"
                    :icon-name="
                      rewardObject.rarity
                        ? rewardObject.type + '-' + rewardObject.rarity
                        : rewardObject.type
                    "
                    :tooltip="
                      rewardObject.rarity
                        ? rewardObject.type + capitalize(rewardObject.rarity)
                        : rewardObject.type
                    "
                    class="no-margin ml-2"
                  />
                </div>
              </div>
            </div>
          </template>
        </tippy>
        <div
          v-for="(rewardObject, indexRew) in row.rewards"
          v-else
          :key="indexRew"
          class="table-row-cell-reward flexing"
        >
          <p>{{ rewardObject.value ?? '' }}</p>
          <app-main-icon
            :icon-size="48"
            :icon-name="
              rewardObject.rarity
                ? rewardObject.type + '-' + rewardObject.rarity
                : rewardObject.type
            "
            :tooltip="
              rewardObject.rarity
                ? rewardObject.type + capitalize(rewardObject.rarity)
                : rewardObject.type
            "
            class="no-margin ml-2"
          />
        </div>
      </div>
    </section>
  </div>

  <component-loading :is-loading="isContentLoading" />
</template>

<script lang="ts">
import { TOURNAMENT_POINTS, TOURNAMENT_TITLES } from '@/globalVariables'
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { capitalize, formatResult } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { RankingType } from '@/interfaces/RankingsInterfaces'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'

interface ComponentData {
  TOURNAMENT_POINTS: typeof TOURNAMENT_POINTS
  TOURNAMENT_TITLES: typeof TOURNAMENT_TITLES
  RankingType: typeof RankingType
}

export default defineComponent({
  components: {
    AppUser,
    AppClubName,
  },
  props: {
    rows: {
      type: Array as PropType<RankingUser[]>,
      default: () => [],
    },
    table: {
      type: String as PropType<RankingType>,
      default: RankingType.Global,
      validator(value: RankingType): boolean {
        return [
          RankingType.Global,
          RankingType.Discipline,
          RankingType.Records,
          RankingType.ClubActivity,
          RankingType.ClubLeaderboard,
          RankingType.TournamentPoints,
          RankingType.TournamentTitles,
          RankingType.TournamentResults,
        ].includes(value)
      },
    },
    width: {
      type: String,
      default: '81rem',
    },
    hideRewards: {
      type: Boolean,
      default: false,
    },
    rewardsInfoButton: {
      type: Boolean,
      default: false,
    },
    hiddenColumns: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    highlightClubMembers: {
      type: Boolean,
      default: true,
    },
  },
  data(): ComponentData {
    return {
      TOURNAMENT_POINTS,
      TOURNAMENT_TITLES,
      RankingType,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userData: 'getUserData',
    }),
    isClubActivityTable(): boolean {
      return this.table === RankingType.ClubActivity
    },
    isTournamentPointsTable(): boolean {
      return this.table === RankingType.TournamentPoints
    },
    isTournamentTitlesTable(): boolean {
      return this.table === RankingType.TournamentTitles
    },
    isTournamentResultsTable(): boolean {
      return this.table === RankingType.TournamentResults
    },
    isContentLoading(): boolean {
      return this.rows == null
    },
  },
  methods: {
    capitalize,
    formatResult,
  },
})
</script>

<style lang="scss" scoped>
.table-standard {
  .table-row {
    &-content {
      width: 81.1875rem;
      height: 4rem;
      margin-bottom: 0.375rem;

      &:nth-child(1) {
        margin-top: 0.6875rem;
      }

      &.my-row {
        @if $isWsm {
          background: rgba(35, 155, 125, 0.7);
        }
        @if $isSsm {
          background: #cd4a8b;
          border: 0.125rem solid #c85493;
        }
      }

      &:nth-child(n + 3).my-row {
        .table-row-position {
          @if $isWsm {
            background: rgba(20, 56, 88, 0.7);
          }
          @if $isSsm {
            background: #fff;
          }
        }
      }
    }

    &-cell {
      width: 16rem;

      &-reward {
        margin-right: 1.25rem;
      }

      &.global-table {
        width: 46rem;

        &-level {
          width: 8.125rem;
        }

        &-club {
          width: 20.75rem;
        }

        &-points {
          width: 16.9375rem;
        }

        &-members {
          width: 20rem;
        }
      }

      &.discipline-table {
        &-level {
          width: 4.875rem;
          margin-left: 7.0625rem;

          &.no-rewards {
            width: 1.875rem;
          }
        }

        &-club {
          min-width: 34rem;
          width: 34rem;

          &.no-rewards {
            width: 51rem;
          }
        }

        &-points {
          width: 5.1875rem;
        }

        &-rewards {
          min-width: 30rem;
          width: 30rem;
          margin-left: 1rem;
        }
      }

      &.records-table {
        &-level {
          width: 4.875rem;
          margin-left: 6rem;
        }

        &-club {
          width: 35.75rem;
        }

        &-season,
        &-result {
          width: 17rem;
        }
      }

      &.tournament-table {
        &-result {
          width: 15rem;
        }

        &-round {
          width: 6.5rem;

          &.is-wide {
            width: 10rem;
          }
        }

        &-rewards {
          min-width: 30rem;
          width: 30rem;
          margin-left: 1rem;

          &.is-wide {
            min-width: 45rem;
            width: 45rem;
          }

          &.is-narrow {
            min-width: 4.5rem;
            width: 4.5rem;
          }
        }
      }
    }

    &-position {
      width: 6.4375rem;
      position: relative;
      clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
      @if $isWsm {
        background: #154c76;
      }
      @if $isSsm {
        background: #fff;
      }
    }

    &-player {
      padding-left: 1.0625rem;
      width: 30rem;
    }

    &-clubName {
      width: 35rem;
    }
  }
}

.table-row-content:nth-child(1) > .table-row-position {
  text-shadow: none;
  @if $isWsm {
    background-image: linear-gradient(to top, #ffef84, #f6c717);
  }
  @if $isSsm {
    background-image: linear-gradient(to top, #daab36, #ffdf91);
  }
}

.table-row-content:nth-child(2) > .table-row-position {
  text-shadow: none;
  @if $isWsm {
    background-image: linear-gradient(to top, #a4a4a4, #fff);
  }
  @if $isSsm {
    background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
  }
}

.table-row-content:nth-child(3) > .table-row-position {
  text-shadow: none;
  @if $isWsm {
    background-image: linear-gradient(to top, #e77447, #ffb99e);
  }
  @if $isSsm {
    background-image: linear-gradient(to top, #f58d57, #ffb28a);
  }
}
</style>
