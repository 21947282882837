<template>
  <div
    v-if="activeTournaments && activeTournaments.length"
    class="tournaments-panel absolute cursor-pointer flex flex-col items-center"
    :class="{ collapsed }"
    @click="
      $router.push({
        name: $getWebView('TournamentsDetail'),
        params: { id: activeTournament.tournament_id },
      })
    "
  >
    <section class="relative tournaments-panel-wrapper flex flex-col items-center">
      <div
        v-if="activeTournaments.length > 1"
        class="nav-arrow nav-arrow-left"
        @click.stop="navTournament(NavDirection.Back)"
      />
      <div class="collapse-btn flexing p-8" @click.stop="toggleCollapse">
        <div class="triangle white" :class="collapsed ? 'triangle-up' : 'triangle-down'" />
      </div>
      <div class="tournaments-panel-decor" />
      <span v-if="remainingTime(activeTournament)" class="tournaments-panel-timer flexing pr-4">
        <app-timer
          :time="remainingTime(activeTournament)"
          no-min-width
          transparent
          centered
          text-size="1.5rem"
          @countdown-action="loadState(true)"
        />
      </span>
      <div v-if="!collapsed" class="tournaments-panel-name flexing relative">
        <app-discipline-icon
          :size="48"
          theme="light"
          :discipline-id="activeTournament.discipline_id"
          class="disc-icon m-auto"
        />
        <p class="text-30 text-texts-standard-default font-bold uppercase ml-4 mt-1">
          {{ activeTournament.name }}
        </p>
      </div>
      <div
        class="tournaments-panel-info flexing text-30 text-texts-standard-default"
        :class="{ running: activeTournament.state === TournamentState.Running }"
      >
        <p v-if="activeTournament.state === TournamentState.Joined">
          {{ $t('tournaments.waitingToStart') }}
        </p>
        <p v-if="activeTournament.state === TournamentState.Running" class="flexing">
          <span>{{ `${$t('tournaments.yourRank')}:` }}</span>
          <span class="font-bold text-texts-standard-important ml-2">
            {{ activeTournament.position ?? '' }}
          </span>
        </p>
      </div>
      <div class="tournaments-panel-navigation flex">
        <!-- TODO dorobit notifikacny stav -->
        <div
          v-for="(nav, i) in activeTournaments"
          :key="i"
          class="nav-btn"
          :class="{ active: i === activeIndex }"
          @click.stop="changeActive(i)"
        />
      </div>
      <div
        v-if="activeTournaments.length > 1"
        class="nav-arrow nav-arrow-right"
        @click.stop="navTournament(NavDirection.Forward)"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import { useTournamentsActiveStore } from '@/store/pinia/tournaments/useTournamentsActiveStore'
import type { TournamentsActiveStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsActiveStateApiResponse'
import { TournamentState } from '@/interfaces/responses/tournaments/TournamentsActiveStateApiResponse'

interface ComponentData {
  activeIndex: number
  collapsed: boolean
  TournamentState: typeof TournamentState
  NavDirection: typeof NavDirection
  intervalRef: ReturnType<typeof setInterval> | null
}

enum NavDirection {
  Back = 'back',
  Forward = 'forward',
}

export default defineComponent({
  // TODO Doriesit s BE lepsi update dat, aktualne sa aktualizuju po prihlaseni do turnaja a vyprsani timera niektoreho z turnajov
  name: 'TournamentsPanel',
  data(): ComponentData {
    return {
      activeIndex: 0,
      collapsed: false,
      TournamentState,
      NavDirection,
      intervalRef: null,
    }
  },
  computed: {
    ...mapState(useTournamentsActiveStore, ['activeTournaments']),
    activeTournament(): TournamentsActiveStateApiResponse {
      return this.activeTournaments[this.activeIndex ?? 0]
    },
  },
  async created(): Promise<void> {
    await this.loadState(true)
  },
  mounted(): void {
    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
    this.intervalRef = setInterval(this.onVisibilityChange, 60e3)
  },
  beforeUnmount(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
    clearInterval(this.intervalRef)
  },
  methods: {
    ...mapActions(useTournamentsActiveStore, ['loadState']),
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') return
      await this.loadState(true)
    },
    remainingTime(tournament: TournamentsActiveStateApiResponse): number {
      if (tournament.ends_in) {
        return tournament.ends_in
      }
      if (tournament.starts_in) {
        return tournament.starts_in
      }
      return 0
    },
    changeActive(index: number): void {
      this.activeIndex = index
    },
    navTournament(direction: NavDirection): void {
      const indexesLength = this.activeTournaments.length - 1
      if (direction === NavDirection.Back) {
        if (this.activeIndex > 0) {
          this.activeIndex--
        } else {
          this.activeIndex = indexesLength
        }
      }
      if (direction === NavDirection.Forward) {
        if (this.activeIndex < indexesLength) {
          this.activeIndex++
        } else {
          this.activeIndex = 0
        }
      }
    },
    toggleCollapse(): void {
      this.collapsed = !this.collapsed
    },
  },
})
</script>

<style lang="scss" scoped>
.tournaments-panel {
  width: 73.75rem;
  height: 10.688rem;
  bottom: 8rem;
  border-style: solid;
  border-width: 0.125rem;
  border-image-slice: 1;
  transition: height 0.5s;
  @if $isSsm {
    border-image-source: linear-gradient(to right, transparent, #586b9d, #586b9d, transparent);
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(52, 65, 93, 0.85),
      rgba(52, 65, 93, 0.85),
      transparent
    );
  }
  @if $isWsm {
    border-image-source: linear-gradient(to right, transparent, #528ebf, #528ebf, transparent);
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(38, 74, 100, 0.85),
      rgba(38, 74, 100, 0.85),
      transparent
    );
  }
  &-timer {
    width: 12.5rem;
    height: 2rem;
    background-image: linear-gradient(
      to right,
      transparent,
      #7e0404,
      #c31818,
      #c31818,
      #7e0404,
      transparent
    );
    position: absolute;
    top: -1rem;
    :deep(.icon-time) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &-name {
    margin-top: 1.5rem;
  }
  &-info {
    width: 31.25rem;
    height: 2.625rem;
    margin-top: 1rem;
    border-style: solid;
    border-width: 0.125rem;
    @if $isSsm {
      border-image-source: linear-gradient(to right, transparent, #0ee7cc, transparent);
      border-image-slice: 1;
      background-image: linear-gradient(to right, transparent, rgba(21, 99, 89, 1), transparent);
    }
    @if $isWsm {
      border-image-source: linear-gradient(to right, transparent, #3fe443, transparent);
      border-image-slice: 1;
      background-image: linear-gradient(to right, transparent, rgba(20, 103, 22, 1), transparent);
    }
    &.running {
      @if $isSsm {
        border-image-source: linear-gradient(to right, transparent, #feb942, transparent);
        border-image-slice: 1;
        background-image: linear-gradient(to right, transparent, rgba(125, 82, 7, 1), transparent);
      }
      @if $isWsm {
        border-image-source: linear-gradient(to right, transparent, #fadd1e, transparent);
        border-image-slice: 1;
        background-image: linear-gradient(to right, transparent, rgba(121, 96, 8, 1), transparent);
      }
    }
  }
  &-navigation {
    margin-top: 0.4rem;
    .nav-btn {
      width: 3.563rem;
      height: 3.563rem;
      @include background(
        url('#{$path-images}/tournaments/ui-panel/toggle-inactive.avif'),
        contain
      );
      &.active {
        @include background(
          url('#{$path-images}/tournaments/ui-panel/toggle-active.avif'),
          contain
        );
        cursor: default;
      }
      &.has-notify {
        @include background(
          url('#{$path-images}/tournaments/ui-panel/toggle-notification.avif'),
          contain
        );
      }
    }
  }
  &-decor {
    width: 6rem;
    height: 9.188rem;
    @include background(url('#{$path-images}/tournaments/ui-panel/cup-small.avif'), contain);
    position: absolute;
    left: 19rem;
    top: 50%;
    transform: translateY(-60%);
  }
  .nav-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 2rem;
    &-left {
      left: 13rem;
      width: 1.875rem;
      height: 3.563rem;
      @include background(url('#{$path-images}/tournaments/ui-panel/arrow-backward.avif'), contain);
    }
    &-right {
      right: 13rem;
      width: 1.875rem;
      height: 3.563rem;
      @include background(url('#{$path-images}/tournaments/ui-panel/arrow-forward.avif'), contain);
    }
  }
  .collapse-btn {
    width: 3.25rem;
    height: 3.25rem;
    border: solid 0.125rem #586b9d;
    background-color: #22222c;
    position: absolute;
    z-index: 1;
    right: 18.5rem;
    top: -1.5rem;
  }
  &-wrapper {
    width: 80rem;
  }
  &.collapsed {
    width: 50rem;
    height: 6.563rem;
    transition: height 0.4s;
    .tournaments-panel-info {
      position: relative;
      top: 0.75rem;
    }
    .tournaments-panel-navigation {
      position: relative;
      top: 0.5rem;
    }
  }
}
</style>
