import { tournamentsCalendarStateEndpoint as TOURNAMENTS_CALENDAR_STATE_ENDPOINT } from '@/globalVariables'
import type { TournamentsCalendarStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentMeta {
  freeDailyJoinsLimit: number
  freeDailyJoins: number
  joinGemsPrice: number
  joinStartsPrice: number
  playMulligansPrice: number
  tournamentPoints: number
  mulligans: number
}

interface TournamentsRankingsStore {
  state: TournamentsCalendarStateApiResponse | null
}

export const useTournamentsCalendarStore = defineStore('tournamentsCalendarStore', {
  state: (): TournamentsRankingsStore => ({
    state: null,
  }),
  getters: {
    meta(): TournamentMeta {
      return {
        freeDailyJoinsLimit: this.state?.free_daily_joins_limit ?? 0,
        freeDailyJoins: this.state?.free_daily_joins ?? 0,
        joinGemsPrice: this.state?.join_gems_price ?? 0,
        joinStartsPrice: this.state?.join_starts_price ?? 0,
        playMulligansPrice: this.state?.play_mulligans_price ?? 0,
        tournamentPoints: this.state?.tournament_points ?? 0,
        mulligans: this.state?.mulligans ?? 0,
      }
    },
    tournaments(): TournamentsCalendarStateApiResponse['tournaments'] | null {
      return this.state?.tournaments ?? null
    },
  },
  actions: {
    async loadState(force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsCalendarStateApiResponse>(
          TOURNAMENTS_CALENDAR_STATE_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
