<template>
  <main class="milestones-main flex flex-col flex-grow px-8 py-4 relative">
    <app-scrollbar
      width="100%"
      height="100%"
      scroll="x"
      slice="x"
      :arrows-z-index="10"
      :arrows="true"
      scroll-to-element=".milestone-indicator.is-active"
      class="z-1"
    >
      <section class="flex flex-row w-full h-full">
        <milestone-column
          v-for="(milestone, index) in milestones"
          :key="index"
          :milestone="milestone"
          :progress="progress"
          :next-milestone="index !== milestones.length - 1 ? milestones[index + 1] : null"
        />
      </section>
    </app-scrollbar>
  </main>
</template>

<script lang="ts">
import MilestoneColumn from './MilestoneColumn.vue'
import { useTournamentsMilestonesStore } from '@/store/pinia/tournaments/useTournamentsMilestonesStore'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

interface ComponentData {
  isLoading: boolean
}

export default defineComponent({
  components: {
    MilestoneColumn,
  },
  data(): ComponentData {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsMilestonesStore, ['milestones', 'progress']),
  },
})
</script>

<style lang="scss" scoped>
.milestones-main {
  height: 39.5rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 50%;
    @include background(
      url('#{$path-images}/tournaments/milestones/milestones-bottom.avif'),
      cover
    );
    transform: translateX(-50%);
    z-index: 0;
  }

  :deep([class*='arrow-']) {
    top: 50%;
  }
}
</style>
