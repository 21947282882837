import {
  tournamentsMilestonesConfigEndpoint as TOURNAMENTS_MILESTONES_CONFIG_API_ENDPOINT,
  tournamentsMilestonesStateEndpoint as TOURNAMENTS_MILESTONES_STATE_API_ENDPOINT,
  tournamentsMilestonesClaimEndpoint as TOURNAMENTS_MILESTONES_CLAIM_API_ENDPOINT,
} from '@/globalVariables'
import {
  MilestoneType,
  type TournamentsMilestonesConfigApiResponse,
} from '@/interfaces/responses/tournaments/TournamentsMilestonesConfigApiResponse'
import {
  MilestoneState,
  type TournamentsMilestonesStateApiResponse,
} from '@/interfaces/responses/tournaments/TournamentsMilestonesStateApiResponse'
import {
  MilestoneType as BGE_MilestoneType,
  MilestoneState as BGE_MilestoneState,
  type TournamentsMilestonesBoardGamesEventStateApiResponse as BGE_TournamentsMilestonesStateApiResponse,
  type Milestone as BGE_Milestone,
} from '@/interfaces/responses/tournaments/TournamentsMilestonesBoardGamesEventStateApiResponse'
import type { TournamentsMilestonesClaimApiResponse } from '@/interfaces/responses/tournaments/TournamentsMilestonesClaimApiResponse'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentsMilestonesStore {
  config: TournamentsMilestonesConfigApiResponse | null
  state: TournamentsMilestonesStateApiResponse | null
}

export const useTournamentsMilestonesStore = defineStore('tournamentsMilestonesStore', {
  state: (): TournamentsMilestonesStore => ({
    config: null,
    state: null,
  }),
  getters: {
    // FE sa zacal robit skor ako BE a zobral sa z BGE. BE nasledne spravil response podla GP.
    // Cize bolo nutne spravit tento prekliaty prevodnik + bolo nutne aj zmenit nejake vypocty progressu v sablone.
    formattedMilestonesResponse(): BGE_TournamentsMilestonesStateApiResponse | null {
      if (!this.config || !this.state) return null

      const milestones: BGE_Milestone[] = [
        {
          milestone: 0,
          points: 0,
          individual: {
            milestoneId: 0,
            claimed: true,
            reward: {
              name: 'empty',
              value: 0,
            },
          },
          premium: {
            milestoneId: 0,
            claimed: true,
            reward: {
              name: 'empty',
              value: 0,
            },
          },
          state: BGE_MilestoneState.Open,
        },
      ]

      for (let i = 0; i < this.config.milestones[MilestoneType.Free].length; i++) {
        const individualMilestoneConfig = this.config.milestones[MilestoneType.Free][i]
        const premiumMilestoneConfig = this.config.milestones[MilestoneType.Premium][i]
        const individualMilestoneState = this.state.milestones[MilestoneType.Free].state[i + 1]
        const premiumMilestoneState = this.state.milestones[MilestoneType.Premium].state[i + 1]

        milestones.push({
          milestone: i + 1,
          points: individualMilestoneConfig.points_limit,
          [BGE_MilestoneType.Individual]: {
            milestoneId: individualMilestoneConfig.id,
            claimed: individualMilestoneState === MilestoneState.Claimed,
            reward: {
              ...individualMilestoneConfig.reward,
              value: +individualMilestoneConfig.reward.value,
            },
          },
          [BGE_MilestoneType.Premium]: {
            milestoneId: premiumMilestoneConfig.id,
            claimed: premiumMilestoneState === MilestoneState.Claimed,
            reward: {
              ...premiumMilestoneConfig.reward,
              value: +premiumMilestoneConfig.reward.value,
            },
          },
          state:
            i + 1 < this.state.milestones[MilestoneType.Free].current_milestone
              ? BGE_MilestoneState.Open
              : BGE_MilestoneState.Locked,
          repeatable: individualMilestoneConfig.repeatable === 1,
        })
      }

      return {
        has_pass: !this.state.milestones[MilestoneType.Premium].locked,
        milestones,
        milestones_progress: {
          milestone: this.state.milestones[MilestoneType.Free].current_milestone - 1,
          next_milestone: this.state.milestones[MilestoneType.Free].current_milestone,
          points: this.state.milestones[MilestoneType.Free].progress.current_progress,
          next_points: this.state.milestones[MilestoneType.Free].progress.next_progress,
          repeatable: this.config.milestones[MilestoneType.Free][0].repeatable === 1,
        },
      }
    },
    milestones(): BGE_TournamentsMilestonesStateApiResponse['milestones'] | null {
      return this.formattedMilestonesResponse?.milestones ?? null
    },
    progress(): BGE_TournamentsMilestonesStateApiResponse['milestones_progress'] | null {
      return this.formattedMilestonesResponse?.milestones_progress ?? null
    },
    lastMilestone(): BGE_TournamentsMilestonesStateApiResponse['milestones'][0]['milestone'] {
      return (
        this.formattedMilestonesResponse?.milestones[
          this.formattedMilestonesResponse?.milestones.length - 1
        ]?.milestone ?? 0
      )
    },
    unclaimedPersonalMilestonesCount(): number {
      return (
        this.formattedMilestonesResponse?.milestones.filter(
          (milestone: BGE_TournamentsMilestonesStateApiResponse['milestones'][0]): boolean =>
            milestone.milestone <
              this.formattedMilestonesResponse.milestones_progress.next_milestone &&
            !milestone.individual.claimed,
        ).length ?? 0
      )
    },
    unclaimedPremiumMilestonesCount(): number {
      if (!this.isTournamentsPassActive) return 0
      return (
        this.formattedMilestonesResponse?.milestones.filter(
          (milestone: BGE_TournamentsMilestonesStateApiResponse['milestones'][0]): boolean =>
            milestone.milestone <
              this.formattedMilestonesResponse.milestones_progress.next_milestone &&
            !milestone.premium.claimed,
        ).length ?? 0
      )
    },
    unclaimedMilestonesCount(): number {
      return this.unclaimedPersonalMilestonesCount + this.unclaimedPremiumMilestonesCount
    },
    isTournamentsPassActive(): BGE_TournamentsMilestonesStateApiResponse['has_pass'] {
      return this.formattedMilestonesResponse?.has_pass ?? false
    },
  },
  actions: {
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config && !force) return
      try {
        this.config = await ApiService.get<TournamentsMilestonesConfigApiResponse>(
          TOURNAMENTS_MILESTONES_CONFIG_API_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState(force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsMilestonesStateApiResponse>(
          TOURNAMENTS_MILESTONES_STATE_API_ENDPOINT,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claimReward(milestoneId: number): Promise<void> {
      if (!this.state) return
      try {
        this.state = await ApiService.post<
          TournamentsMilestonesClaimApiResponse,
          { milestoneId: number }
        >(
          TOURNAMENTS_MILESTONES_CLAIM_API_ENDPOINT,
          {
            milestoneId,
          },
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
