import {
  tournamentsDetailStateEndpoint as TOURNAMENTS_DETAIL_STATE_ENDPOINT,
  tournamentsDetailoinEndpoint as TOURNAMENTS_DETAIL_JOIN_ENDPOINT,
  TOURNAMENTS_STORAGE_KEY,
  TOURNAMENT_START,
  TOURNAMENT_END,
} from '@/globalVariables'
import type { TournamentsDetailStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsDetailStateApiResponse'
import type { TournamentEventStorageItem } from '@/interfaces/Tournaments'
import { ApiService } from '@/services/ApiService'
import { defineStore } from 'pinia'

interface TournamentMeta {
  freeDailyJoinsLimit: number
  freeDailyJoins: number
  joinGemsPrice: number
  joinStartsPrice: number
  playMulligansPrice: number
  mulligans: number
  playWithMulligans: boolean
}

interface TournamentsDetailStore {
  state: TournamentsDetailStateApiResponse | null
}

export const useTournamentsDetailStore = defineStore('tournamentsDetailStore', {
  state: (): TournamentsDetailStore => ({
    state: null,
  }),
  getters: {
    meta(): TournamentMeta {
      return {
        freeDailyJoinsLimit: this.state?.free_daily_joins_limit ?? 0,
        freeDailyJoins: this.state?.free_daily_joins ?? 0,
        joinGemsPrice: this.state?.join_gems_price ?? 0,
        joinStartsPrice: this.state?.join_starts_price ?? 0,
        playMulligansPrice: this.state?.play_mulligans_price ?? 0,
        mulligans: this.state?.mulligans ?? 0,
        playWithMulligans: this.state?.play_with_mulligans ?? false,
      }
    },
    tournament(): TournamentsDetailStateApiResponse['tournament'] | null {
      return this.state?.tournament ?? null
    },
    rankings(): TournamentsDetailStateApiResponse['ranking'] | null {
      if (!this.state?.ranking) return null

      const roundCount = this.tournament?.rounds_total ?? 0

      for (const key in this.state.ranking) {
        if (roundCount > 1) {
          this.state.ranking[key].rounds = this.state.ranking[key].rounds || []
          for (let i = this.state.ranking[key].rounds.length; i < roundCount; i++) {
            this.state.ranking[key].rounds.push({ round: i + 1, result: 0 })
          }
        } else {
          this.state.ranking[key].rounds = null
        }
      }

      return this.state?.ranking ?? null
    },
    rewards(): TournamentsDetailStateApiResponse['rewards'] | null {
      return this.state?.rewards ?? null
    },
  },
  actions: {
    async loadState(tournamentId: number, force: boolean = false): Promise<void> {
      if (this.state && !force) return
      try {
        this.state = await ApiService.get<TournamentsDetailStateApiResponse>(
          TOURNAMENTS_DETAIL_STATE_ENDPOINT + tournamentId,
          { force: true },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async joinTournament(tournamentId: number): Promise<boolean> {
      if (!this.state) return false

      try {
        this.state = await ApiService.post<TournamentsDetailStateApiResponse>(
          TOURNAMENTS_DETAIL_JOIN_ENDPOINT + tournamentId,
          null,
          {
            force: true,
          },
        )

        // TODO: Return false if is not possible to join.

        const data = JSON.parse(
          localStorage.getItem(TOURNAMENTS_STORAGE_KEY) || '[]',
        ) as TournamentEventStorageItem[]

        data.push(
          {
            id: this.tournament.id,
            type: TOURNAMENT_START,
            tournamentName: this.tournament.name,
            disciplineId: this.tournament.discipline_id,
            // Zaokruhlime na cele sekundy.
            timestamp: Math.trunc(new Date(this.tournament.start_date).getTime() / 1e3),
          },
          {
            id: this.tournament.id,
            type: TOURNAMENT_END,
            tournamentName: this.tournament.name,
            disciplineId: this.tournament.discipline_id,
            // Zaokruhlime na cele sekundy.
            timestamp: Math.trunc(new Date(this.tournament.end_date).getTime() / 1e3),
          },
        )

        localStorage.setItem(TOURNAMENTS_STORAGE_KEY, JSON.stringify(data))

        return true
      } catch (error: unknown) {
        console.error(error)
      }

      return false
    },
  },
})
