<template>
  <header
    class="calendar-header w-full flex flex-row flex-shrink-0 justify-between items-center relative"
  >
    <div>
      <template v-if="!isLoading">
        <app-select
          :options="disciplines"
          :default="disciplineId"
          class="selectbox-disciplines"
          @select="$emit('set-discipline-id', $event)"
        />
      </template>
      <component-loading v-else :is-loading="true" height="100%" />
    </div>
    <div class="calendar-limit flexing absolute">
      <ul class="flexing text-texts-standard-default text-28">
        <li>
          {{ $t('tournaments.dailyLimit') }}
          <span class="font-bold">
            <span class="text-orange">
              {{ meta.freeDailyJoins }}
            </span>
            / {{ meta.freeDailyJoinsLimit }}
          </span>
        </li>
        <li>
          {{ $t('tournaments.tournamentOverLimit') }} {{ meta.joinGemsPrice }}
          <app-main-icon icon-name="gems" :icon-size="32" class="relative top-1 no-margin" />
        </li>
      </ul>
    </div>
    <div class="flexing space-x-8">
      <tournament-box :resources="[{ value: meta.mulligans, icon: MULLIGANS }]" />
      <tournament-info-button :context="TournamentInfoButtonContext.Tournaments" />
    </div>
  </header>
</template>

<script lang="ts">
import { ARENA, MULLIGANS } from '@/globalVariables'
import TournamentBox from '../TournamentBox.vue'
import TournamentInfoButton, { TournamentInfoButtonContext } from '../TournamentInfoButton.vue'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useTournamentsCalendarStore } from '@/store/pinia/tournaments/useTournamentsCalendarStore'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import { defineComponent, type PropType } from 'vue'
import { mapState, mapActions } from 'pinia'

interface SelectOption {
  value: number | 'all'
  name: string
}

interface ComponentData {
  MULLIGANS: typeof MULLIGANS
  TournamentInfoButtonContext: typeof TournamentInfoButtonContext
  isLoading: boolean
}

export default defineComponent({
  components: {
    TournamentBox,
    TournamentInfoButton,
  },
  props: {
    disciplineId: {
      type: [Number, String] as PropType<SelectOption[keyof SelectOption]>,
      required: true,
    },
  },
  emits: ['set-discipline-id'],
  data(): ComponentData {
    return {
      MULLIGANS,
      TournamentInfoButtonContext,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, ['getDisciplinesMenuData']),
    ...mapState(useTournamentsCalendarStore, ['meta']),
    disciplines(): SelectOption[] {
      return [
        { value: 'all', name: this.$t('tournaments.allDisciplines') },
        ...this.getDisciplinesMenuData.map(
          ({ id }: DisciplineMenuData): SelectOption => ({
            value: id,
            name: this.$translateDiscipline(id),
          }),
        ),
      ]
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadDisciplinesForMenu(ARENA)
    this.isLoading = false
  },
  methods: {
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
  },
})
</script>

<style lang="scss" scoped>
.calendar-header {
  height: 7.375rem;
  padding: 1.25rem 1.875rem;

  .calendar-limit {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 62.5rem;
    height: 4.875rem;
    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
    }
    @if $isWsm {
      background-image: linear-gradient(to right, transparent, #144a73, #144a73, transparent);
    }

    ul > li:not(:last-of-type)::after {
      content: '|';
      width: 0.125rem;
      height: 1.25rem;
      margin: 0 0.5rem;
    }

    .text-orange {
      color: #ff6344;
    }
  }
}
</style>
