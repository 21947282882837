<template>
  <section class="detail-view w-full min-h-0 flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="w-full h-full flex flex-col">
      <div
        class="detail-main w-full min-h-0 flex flex-row flex-grow justify-center items-center px-8 pt-6 pb-4 relative"
      >
        <tournament-box
          class="tournament-box absolute"
          :resources="[{ value: meta.mulligans, icon: MULLIGANS }]"
        />
        <div class="w-full h-full flexing space-x-8">
          <div
            class="left-side h-full flex flex-col justify-start items-center"
            :class="{
              'is-finished': isFinished,
            }"
          >
            <div class="header w-full flex flex-col justify-start items-center">
              <app-discipline-icon
                class="mt-10 mb-6"
                :discipline-id="tournament.discipline_id"
                :size="120"
                theme="light"
              />
              <p class="text-36 text-texts-standard-important font-bold uppercase">
                {{ $translateDiscipline(tournament.discipline_id) }}
              </p>
              <p class="text-30 text-texts-standard-default uppercase truncate">
                {{ tournament.name }}
              </p>
            </div>
            <div
              class="attribute w-full flex flex-col justify-center items-center pt-2 -mt-8 mb-6 relative"
              :class="{
                'opacity-50': isFinished,
              }"
            >
              <p class="text-30 text-texts-standard-important font-medium">
                {{ $t('tournaments.attribute') }}
              </p>
              <p class="flexing text-48 text-texts-standard-default font-bold">
                {{ tournament.attribute }}
                <app-discipline-icon
                  class="attribute-icon !border-transparent ml-2 relative -mt-2"
                  :discipline-id="tournament.discipline_id"
                  :size="70"
                  :is-transparent-bg="true"
                />
                <tippy
                  theme="WSM"
                  placement="top"
                  max-width="35rem"
                  class="absolute bottom-4 right-4"
                >
                  <app-icon icon-name="info-50" />
                  <template #content>
                    <div class="tooltip-content text-texts-standard-default text-30">
                      <p>
                        {{
                          !tournament.benefit
                            ? $t('tournaments.attributesInfo1')
                            : $t('tournaments.attributesInfo2')
                        }}
                      </p>
                    </div>
                  </template>
                </tippy>
              </p>
            </div>
            <template v-if="isFinished">
              <p class="text-30 text-texts-standard-default font-medium mt-auto">
                {{ $t('tournaments.finalRank') }}
              </p>
              <div class="w-full px-2 mb-8">
                <ranking-player
                  v-if="rankings?.player"
                  :player-data="rankings.player"
                  :ranking-type="RankingType.TournamentResults"
                  :hidden-columns="['result', 'rewards']"
                />
              </div>
              <p class="text-30 text-texts-standard-default font-medium uppercase">
                {{ $t('tournaments.yourRewards') }}
              </p>
              <div v-if="rankings?.player?.rewards" class="rewards w-full flexing p-4">
                <reward-box
                  v-for="(reward, index) in rankings?.player?.rewards"
                  :key="index"
                  :reward-icon-size="56"
                  :reward-icon-name="reward.type"
                  :reward-number="reward.value"
                />
              </div>
            </template>
            <template v-else>
              <div
                class="benefit w-full flexing mb-auto"
                :class="{ 'is-disabled': !tournament.benefit }"
              >
                <p v-if="!tournament.benefit" class="w-4/5 text-30 text-texts-standard-default">
                  {{ $t('tournaments.benefitsNotAvailable') }}
                </p>
                <span v-else>
                  <use-benefit-button
                    v-for="(slot, slotId) in benefitTournamentsSlots"
                    :key="slotId"
                    btn-size="sm"
                    :slot-data="slot"
                    :slot-id="slotId.toString()"
                    :benefit-usage="requiredBenefits"
                    @click="showSelectBenefitPopup = true"
                    @reload-data="loadState"
                  />
                </span>
              </div>
              <div class="w-full flex flex-col justify-center items-center mt-auto mb-4">
                <p class="text-30 text-texts-standard-default">
                  {{
                    isRunning
                      ? $t('tournaments.tournamentEndsIn')
                      : $t('tournaments.tournamentStart')
                  }}
                </p>
                <p v-if="isRunning" class="time no-bg flexing mb-4">
                  <app-timer
                    v-if="remainingTimeToEnd > 0"
                    :time="remainingTimeToEnd"
                    class="timer flexing"
                    @countdown-action="loadState"
                  />
                </p>
                <p v-else class="time flexing mb-4">
                  <span class="text-36 text-texts-standard-important font-bold">
                    {{ getFormattedTimeFromHMS(tournament.start_date) }}
                  </span>
                </p>
                <p
                  v-if="isJoined"
                  class="flexing text-40 text-texts-standard-positive font-bold uppercase"
                >
                  {{ $t('tournaments.signedUp') }}
                </p>
                <app-button
                  v-else-if="isRunningAndCanPlay && !meta.playWithMulligans"
                  btn-id="enter-button-exclamation"
                  class="enter-button w-full"
                  btn-size="md"
                  :btn-type="buttonType"
                  @click="enterTournament"
                >
                  {{ buttonText }}
                </app-button>
                <app-multi-button
                  v-else
                  class="w-full"
                  :class="isRunningAndCanPlay ? 'enter-button' : 'join-button'"
                  btn-size="md"
                  :btn-type="buttonType"
                  :btn-text="buttonText"
                  :no-icon="true"
                  :disabled="meta.playWithMulligans && meta.playMulligansPrice > meta.mulligans"
                  @click="onButtonClick"
                >
                  <template #rightPart>
                    <span class="text-texts-standard-default">
                      {{ buttonPrice }}
                    </span>
                    <app-main-icon
                      :icon-name="buttonIcon"
                      :icon-size="48"
                      class="pointer-events-auto"
                    />
                  </template>
                </app-multi-button>
                <info-popup
                  v-if="isConfirmPopupOpen"
                  :popup-title="$t('tournaments.tournament')"
                  class="tournaments-join-confirm-popup"
                  width="50.375rem"
                  @close="isConfirmPopupOpen = false"
                >
                  <div
                    class="tournaments-join-confirm-popup-content w-full flex flex-col flex-grow text-36 text-texts-standard-default space-y-6"
                  >
                    <p class="tournaments-join-confirm-popup-content-header text-center uppercase">
                      {{ $t('tournaments.signUpConfirm') }}
                    </p>
                    <p
                      class="tournaments-join-confirm-popup-content-text text-texts-standard-danger text-center"
                    >
                      {{ $t('tournaments.singOutImpossible') }}
                    </p>
                    <p
                      class="tournaments-join-confirm-popup-content-text text-center relative top-4"
                    >
                      {{ $t('tournaments.entryFee') }}:
                    </p>
                    <div class="w-full flexing">
                      <tournament-box :resources="confirmPopupResources" />
                    </div>
                    <div class="w-full flexing">
                      <app-button
                        class="join-button"
                        btn-size="md"
                        :btn-type="buttonType"
                        :loading="isJoining"
                        :disabled="isJoining"
                        @click="joinTournament"
                      >
                        {{ buttonText }}
                      </app-button>
                    </div>
                  </div>
                </info-popup>
                <info-popup
                  v-if="isErrorPopupOpen"
                  :popup-title="$t('tournaments.tournament')"
                  class="tournaments-error-popup"
                  width="50.375rem"
                  @close="isErrorPopupOpen = false"
                >
                  <div
                    class="tournaments-error-popup-content w-full flex flex-col flex-grow text-36 text-texts-standard-default space-y-6"
                  >
                    <p
                      class="tournaments-error-popup-content-text text-texts-standard-danger text-center uppercase"
                    >
                      {{ $t('tournaments.tournamentFull') }}
                    </p>
                    <p class="tournaments-error-popup-content-header text-center">
                      {{ $t('tournaments.tryOtherTournament') }}
                    </p>
                    <div class="w-full flexing">
                      <app-button
                        class="continue-button"
                        btn-size="md"
                        btn-type="secondary"
                        @click="isErrorPopupOpen = false"
                      >
                        {{ $t('tournaments.continue') }}
                      </app-button>
                    </div>
                  </div>
                </info-popup>
              </div>
            </template>
          </div>
          <div
            class="right-side h-full flex flex-col justify-start items-center flex-grow relative"
          >
            <div class="header w-full flex flex-row justify-between items-center mb-4">
              <p
                class="text-36 font-bold uppercase"
                :class="
                  isFinished ? 'text-texts-standard-default' : 'text-texts-standard-important'
                "
              >
                {{ title }}
              </p>
              <p class="flexing text-28 text-texts-standard-default">
                <template v-if="!isFinished">
                  <template v-if="!isOpen">
                    {{ $t('tournaments.refresh') }}
                  </template>
                  <app-icon
                    icon-name="btn-refresh"
                    class="clickable-element cursor-pointer ml-4"
                    :class="{
                      'opacity-50 pointer-events-none': isOpen,
                    }"
                    :disabled="isOpen"
                    @click="loadState"
                  />
                </template>
                <template v-else>
                  {{ getFormattedDateFromYMD(tournament.start_date) }}
                  <span class="text-texts-standard-important ml-2">
                    {{ getFormattedTimeFromHMS(tournament.start_date) }}
                  </span>
                </template>
              </p>
            </div>
            <div
              v-if="!isReloading"
              class="w-full min-h-0 flex flex-col"
              :class="{ 'opacity-50': isOpen || isRunningAndCanNotPlay }"
            >
              <ranking-table-head
                :ranking-type="RankingType.TournamentResults"
                :rewards-info-button="!isOpen && !isRunningAndCanNotPlay"
                :hidden-columns="hiddenColumns"
                :rounds="rounds"
              />
              <app-scrollbar
                class="rankings-scrollbar"
                width="100%"
                height="100%"
                scroll="y"
                slide="y"
              >
                <ranking-row-table
                  :table="RankingType.TournamentResults"
                  :rewards-info-button="!isOpen && !isRunningAndCanNotPlay"
                  :hidden-columns="hiddenColumns"
                  :rows="formattedRankings"
                  width="100%"
                  :highlight-club-members="false"
                />
              </app-scrollbar>
            </div>
            <component-loading v-else :is-loading="true" height="100%" />
          </div>
        </div>
      </div>
      <info-popup
        v-if="showSelectBenefitPopup"
        :popup-title="$t('benefits.buttonActiveBenefit')"
        @close="showSelectBenefitPopup = false"
      >
        <select-benefit
          :allowed-benefit-types="benefitTypes"
          :required-benefits="requiredBenefits"
          :current-slots="benefitSlots()"
          @close-select-popup="closeSelectAndRefreshSlots"
        />
      </info-popup>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import {
  MULLIGANS,
  minigameUrl as MINIGAME_URL,
  STARTS,
  GEMS,
  BENEFITS_TOURNAMENTS,
  gamesConfigEndpoint,
  TOURNAMENTS_CONFIG,
} from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS, openMiniGame } from '@/helpers'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'

import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RewardBox from '@/components/GlobalComponents/RewardBox.vue'
import TournamentBox from '../TournamentBox.vue'
import { RankingType, type RankingUser } from '@/interfaces/RankingsInterfaces'
import { useModeStore } from '@/store/pinia/modeStore'
import { useTournamentsDetailStore } from '@/store/pinia/tournaments/useTournamentsDetailStore'
import { TournamentState } from '@/interfaces/responses/tournaments/TournamentsDetailStateApiResponse'
import { TournamentUserState } from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import type Reward from '@/interfaces/Reward'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import type { UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'
import type { BenefitSlot, ActiveBenefitSlots } from '@/interfaces/Benefits'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useTournamentsActiveStore } from '@/store/pinia/tournaments/useTournamentsActiveStore'

interface TournamentsConfigApiResponse {
  benefit: number
  cost: number
  equipment_durability: number
  parameter: string
}

interface ComponentData {
  GEMS: typeof GEMS
  STARTS: typeof STARTS
  MULLIGANS: typeof MULLIGANS
  TournamentState: typeof TournamentState
  RankingType: typeof RankingType
  isConfirmPopupOpen: boolean
  isErrorPopupOpen: boolean
  isLoading: boolean
  isReloading: boolean
  isJoining: boolean
  showSelectBenefitPopup: boolean
  requiredBenefits: number
}

export default defineComponent({
  components: {
    InfoPopup,
    SelectBenefit,
    UseBenefitButton,
    RankingTableHead,
    RankingRowTable,
    RankingPlayer,
    RewardBox,
    TournamentBox,
  },
  data(): ComponentData {
    return {
      GEMS,
      STARTS,
      MULLIGANS,
      TournamentState,
      RankingType,
      isConfirmPopupOpen: false,
      isErrorPopupOpen: false,
      isLoading: false,
      isReloading: false,
      isJoining: false,
      showSelectBenefitPopup: false,
      requiredBenefits: 1,
    }
  },
  computed: {
    ...mapState(useTournamentsDetailStore, ['meta', 'tournament', 'rankings', 'rewards']),
    ...mapState(useModeStore, { mode: 'getMode' }),
    ...mapState(useResponseTaskStore, ['getBenefitsSlots']),
    ...mapState(useCoreStore, ['tournamentIsFull']),
    benefitTypes(): string[] {
      return this.benefitTournaments.map((benefit): string => benefit.benefit_slot_type)
    },
    benefitTournaments(): UserBenefitsSlotsApiResponse[] {
      return (
        this.getBenefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_TOURNAMENTS,
        ) ?? []
      )
    },
    benefitTournamentsSlots(): BenefitSlot['slots'] {
      return this.benefitTournaments.reduce(
        (acc: BenefitSlot['slots'], val: UserBenefitsSlotsApiResponse) => {
          acc[val?.slot_id] = val.benefit
          return acc
        },
        {},
      )
    },
    isOpen(): boolean {
      return this.tournament.tournament_state === TournamentState.Open
    },
    isJoined(): boolean {
      return (
        this.tournament.tournament_state === TournamentState.Open &&
        this.tournament.user_state === TournamentUserState.Joined
      )
    },
    isRunning(): boolean {
      return this.tournament.tournament_state === TournamentState.Running
    },
    isRunningAndCanPlay(): boolean {
      return this.isRunning && this.tournament.user_state === TournamentUserState.Running
    },
    isRunningAndCanNotPlay(): boolean {
      return this.isRunning && this.tournament.user_state === null
    },
    isFinished(): boolean {
      return this.tournament.tournament_state === TournamentState.Finished
    },
    remainingTimeToStart(): number {
      return this.tournament.start_date
        ? (new Date(this.tournament.start_date).getTime() - Date.now()) / 1e3
        : 0
    },
    remainingTimeToEnd(): number {
      return this.tournament.end_date
        ? (new Date(this.tournament.end_date).getTime() - Date.now()) / 1e3
        : 0
    },
    buttonType(): string {
      if (this.isRunningAndCanPlay) return this.meta.playWithMulligans ? 'primary' : 'secondary'
      return this.meta.freeDailyJoins > 0 ? 'confirm' : 'credit'
    },
    buttonText(): string {
      if (this.isRunningAndCanPlay) {
        return this.meta.playWithMulligans
          ? this.$t('tournaments.playAgain')
          : this.$t('tournaments.start')
      }
      return this.isRunning ? this.$t('tournaments.join') : this.$t('tournaments.signUp')
    },
    buttonPrice(): number {
      if (this.isRunningAndCanPlay && this.meta.playWithMulligans)
        return this.meta.playMulligansPrice
      return this.meta.freeDailyJoins > 0 ? this.meta.joinStartsPrice : this.meta.joinGemsPrice
    },
    buttonIcon(): string {
      if (this.isRunningAndCanPlay && this.meta.playWithMulligans) return MULLIGANS
      return this.meta.freeDailyJoins > 0 ? STARTS : GEMS
    },
    confirmPopupResources(): { value: number; icon: string }[] {
      return this.meta.freeDailyJoins > 0
        ? [{ value: this.meta.joinStartsPrice, icon: STARTS }]
        : [
            { value: this.meta.joinStartsPrice, icon: STARTS },
            { value: this.meta.joinGemsPrice, icon: GEMS },
          ]
    },
    title(): string {
      if (this.isOpen) return this.$t('tournaments.tournamentNotStarted')
      if (this.isRunning) return this.$t('tournaments.tournamentInProgress')
      if (this.isFinished) return this.$t('tournaments.tournamentEnded')
      return ''
    },
    formattedRankings(): RankingUser[] {
      if (this.isOpen || this.isRunningAndCanNotPlay) {
        return Object.values(this.rewards).map(
          (rewards: Reward[]): RankingUser => ({
            id: '',
            name: '',
            LEVEL: 0,
            clubId: '',
            clubLogoId: 0,
            clubLogoBgId: 0,
            eventBadges: [],
            rewards,
            result: 0,
            discipline_id: 0,
          }),
        )
      }

      return (
        Object.keys(this.rankings).reduce((array: RankingUser[], userId: string): RankingUser[] => {
          if (userId !== 'player') {
            array.push({
              id: userId,
              name: this.rankings[userId].username,
              level: this.rankings[userId].LEVEL,
              clubId: this.rankings[userId].club_id,
              clubLogoId: this.rankings[userId].club_logo_id,
              clubLogoBgId: this.rankings[userId].club_background_logo_id,
              eventBadges: this.rankings[userId].event_badges ?? null,
              discipline_id: this.rankings[userId].discipline_id || this.tournament.discipline_id,
              ...this.rankings[userId],
            })
          }
          return array
        }, []) ?? []
      )
    },
    hiddenColumns(): string[] {
      if (this.isOpen || this.isRunningAndCanNotPlay) return ['name', 'result']
      return []
    },
    rounds(): number | null {
      return this.formattedRankings?.[0]?.rounds?.length ?? null
    },
  },
  watch: {
    tournamentIsFull(value: boolean): void {
      if (!value) return
      this.removeAxiosErrors()
      this.loadState()
    },
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadState()

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadState()
    this.isLoading = false
  },
  methods: {
    getFormattedTimeFromHMS,
    getFormattedDateFromYMD,
    ...mapActions(useTournamentsDetailStore, {
      _loadState: 'loadState',
      _joinTournament: 'joinTournament',
    }),
    ...mapActions(useCoreStore, ['removeAxiosErrors']),
    ...mapActions(useTournamentsActiveStore, { loadActiveTournaments: 'loadState' }),
    async loadState(): Promise<void> {
      this.isReloading = true
      await this.loadConfig()
      await this._loadState(+this.$route.params.id, true)
      this.isReloading = false
    },
    async loadConfig(): Promise<void> {
      const tournamentsConfig = await this.$axios.post<
        {},
        TournamentsConfigApiResponse,
        { keys: string }
      >(gamesConfigEndpoint, {
        keys: TOURNAMENTS_CONFIG,
      })

      this.requiredBenefits = tournamentsConfig?.cost ?? this.requiredBenefits
    },
    async joinTournament(): Promise<void> {
      this.isJoining = true
      const isJoined = await this._joinTournament(this.tournament.id)
      this.isJoining = false
      this.isConfirmPopupOpen = false
      if (isJoined) {
        this.loadActiveTournaments(true)
      } else {
        this.isErrorPopupOpen = true
      }
    },
    enterTournament(): void {
      openMiniGame(
        MINIGAME_URL,
        {
          params: {
            mode: this.mode?.tournament,
            discipline_id: this.tournament.discipline_id,
            tournament_id: this.tournament.id,
          },
        },
        this.$router,
      )
    },
    onButtonClick(): void {
      if (this.isRunningAndCanPlay) return this.enterTournament()
      this.isConfirmPopupOpen = true
    },
    async onConfirmButtonClick(): Promise<void> {
      await this.joinTournament()
      this.isConfirmPopupOpen = false
    },
    benefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.benefitTournamentsSlots

      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
    async closeSelectAndRefreshSlots(): Promise<void> {
      this.showSelectBenefitPopup = false
      this.loadState()
    },
  },
})
</script>

<style lang="scss" scoped>
.detail-view {
  .detail-main {
    .left-side {
      width: 32.875rem;
      background-color: #202f53;
      border: solid 0.125rem #4c648f;

      &.is-finished {
        :deep(.player-position-position) {
          flex-shrink: 0;
        }

        :deep(.player-position-player) {
          @apply truncate;
          width: auto;
          padding-right: 1rem;
          margin-right: auto;
        }

        .rewards {
          background-color: #232a40;
        }
      }

      .header {
        height: 21.813rem;
        @include background(url('#{$path-images}/tournaments/detail/header-bg.avif'), contain);
      }

      .attribute {
        height: 7.75rem;
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(52, 65, 93, 0.85) 20%,
          rgba(52, 65, 93, 0.85) 80%,
          transparent 100%
        );
        border-style: solid;
        border-width: 0.125rem;
        border-image-source: linear-gradient(
          to right,
          transparent 0%,
          rgba(88, 107, 157, 0.85) 20%,
          rgba(88, 107, 157, 0.85) 80%,
          transparent 100%
        );
        border-image-slice: 1;
        line-height: 1.25;

        &-icon {
          width: 4.375rem;
          height: 4.375rem;
        }
      }

      .benefit {
        height: 7.5rem;
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(52, 65, 93, 0.85) 20%,
          rgba(52, 65, 93, 0.85) 80%,
          transparent 100%
        );

        &.is-disabled {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(132, 30, 17, 0.7),
            transparent
          );
        }
      }

      .time {
        width: 26.25rem;
        height: 3.75rem;

        &:not(.no-bg) {
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(14, 231, 204, 0.5),
            transparent
          );
        }

        .timer {
          width: 16.875rem;
        }
      }

      .join-button,
      .enter-button {
        width: 30.625rem;
      }
    }

    .right-side {
      width: 81.25rem;

      .header {
        height: 4.375rem;
      }
    }
  }

  .tournament-box {
    top: -5.5rem;
    right: 2rem;
  }

  :deep(.player-position-position.is-rank-1) {
    @if $isWsm {
      background-image: linear-gradient(to top, #ffef84, #f6c717);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #daab36, #ffdf91);
    }
  }

  :deep(.player-position-position.is-rank-2) {
    @if $isWsm {
      background-image: linear-gradient(to top, #a4a4a4, #fff);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
    }
  }

  :deep(.player-position-position.is-rank-3) {
    @if $isWsm {
      background-image: linear-gradient(to top, #e77447, #ffb99e);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #f58d57, #ffb28a);
    }
  }
}

.tournaments-join-confirm-popup {
  &-content {
    padding: 2.5rem 1.5rem 1.5rem;

    :deep(.join-button) {
      width: 100% !important;
    }
  }
}

.tournaments-error-popup {
  &-content {
    padding: 2.5rem 1.5rem 1.5rem;

    :deep(.continue-button) {
      width: 100% !important;
    }
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!border-transparent {
  border-color: transparent !important;
}
</style>
