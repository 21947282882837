<template>
  <section class="rankings-view w-full flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="flex flex-col flex-grow">
      <div
        class="rankings-main flex flex-row flex-grow justify-center items-center px-8 py-4 space-x-8"
      >
        <div class="left-side">
          <ranking-box-position
            v-if="formattedRankings.length"
            :ranking-position="formattedRankings?.slice(0, 3)"
            :ranking-type="rankingType"
          />
        </div>
        <div class="right-side">
          <header class="right-side-header relative w-full flex justify-end">
            <app-select
              :options="periods"
              :default="period"
              class="selectbox-periods"
              @select="setPeriod"
            />
            <tippy theme="WSM" placement="left" max-width="35rem">
              <app-icon icon-name="info-70" />
              <template #content>
                <div class="tooltip-content text-texts-standard-default text-30">
                  <p>
                    {{ $replacePlaceholder($t('rankingsGroup.rankingRefreshTime'), '%s', '30') }}
                  </p>
                </div>
              </template>
            </tippy>
          </header>
          <ranking-table-head :ranking-type="rankingType" />
          <app-scrollbar
            class="rankings-scrollbar"
            width="100%"
            height="35.8rem"
            scroll="y"
            slide="y"
          >
            <ranking-row-table :rows="formattedRankings" :table="rankingType" width="100%" />
          </app-scrollbar>
          <ranking-player
            v-if="rankings?.player"
            class="flex-shrink-0"
            :player-data="rankings.player"
            :ranking-type="rankingType"
          />
        </div>
      </div>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import RankingBoxPosition from '@/components/Rankings/RankingBoxPosition.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import { useTournamentsRankingsStore } from '@/store/pinia/tournaments/useTournamentsRankingsStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { RankingType, type RankingUser } from '@/interfaces/RankingsInterfaces'
import { SeasonType } from '@/interfaces/Seasons'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

interface SelectOption {
  value: string
  name: string
}

interface ComponentData {
  pathImages: typeof pathImages
  RankingType: typeof RankingType
  period: string
  isLoading: boolean
}

export default defineComponent({
  components: {
    RankingBoxPosition,
    RankingTableHead,
    RankingRowTable,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      pathImages,
      RankingType,
      period: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsRankingsStore, ['rankings']),
    ...mapState(useMainSeasonStore, {
      seasonStats: 'getSeasonStats',
    }),
    rankingType(): RankingType {
      switch (this.$route.name) {
        default:
        case this.$getWebView('TournamentsRankingsPoints'):
          return RankingType.TournamentPoints
        case this.$getWebView('TournamentsRankingsTitles'):
          return RankingType.TournamentTitles
      }
    },
    type(): string {
      return this.rankingType.replace(/^tournaments?/, '').toLowerCase()
    },
    currentSeason(): string {
      return `${this.seasonStats.seasonMonth}/${this.seasonStats.seasonYear}`
    },
    previousSeason(): string {
      let year = +this.seasonStats.seasonYear
      let month = +this.seasonStats.seasonMonth

      if (month === 1) {
        month = 12
        year--
      } else {
        month--
      }

      return `${month.toString().padStart(2, '0')}/${year}`
    },
    periods(): SelectOption[] {
      return [
        {
          value: this.currentSeason,
          name: this.$t('tournaments.currentSeason'),
        },
        {
          value: this.previousSeason,
          name: this.$t('tournaments.previousSeason'),
        },
        {
          value: 'all',
          name: this.$t('tournaments.overall'),
        },
      ]
    },
    formattedRankings(): RankingUser[] {
      if (!this.rankings) return []
      return (
        Object.keys(this.rankings).reduce((array: RankingUser[], userId: string): RankingUser[] => {
          if (userId !== 'player') {
            array.push({
              id: userId,
              name: this.rankings[userId].username,
              points: this.rankings[userId].RANKING_POINTS,
              level: this.rankings[userId].LEVEL,
              clubId: this.rankings[userId].club_id,
              clubLogoId: this.rankings[userId].club_logo_id,
              clubLogoBgId: this.rankings[userId].club_background_logo_id,
              eventBadges: this.rankings[userId].event_badges ?? null,
              ...this.rankings[userId],
            })
          }
          return array
        }, []) ?? []
      )
    },
    watcher(): string {
      return [this.rankingType, this.period].join('|')
    },
  },
  watch: {
    async watcher(): Promise<void> {
      this.isLoading = true
      await this.loadState(
        {
          type: this.type,
          period: this.period,
        },
        true,
      )
      this.isLoading = false
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await Promise.all([
      this.loadState({ type: this.type, period: this.period }, true),
      this.loadSeasonStats(SeasonType.Current),
    ])
    this.isLoading = false

    this.period = this.currentSeason
  },
  methods: {
    ...mapActions(useTournamentsRankingsStore, ['loadState']),
    ...mapActions(useMainSeasonStore, ['loadSeasonStats']),
    setPeriod(value: string): void {
      this.period = value
    },
  },
})
</script>

<style lang="scss" scoped>
.rankings-view {
  .rankings-main {
    .left-side {
      width: 33.125rem;

      :deep(.position-box) {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-top: 2rem;
        }

        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            background-image: url('#{$path-images}tournaments/rankings/box-#{$i}-bg.avif') !important;

            .position-box-badge {
              background-image: url('#{$path-images}tournaments/rankings/number-#{$i}.avif') !important;
            }

            .position-box-cup {
              background-image: url('#{$path-images}tournaments/rankings/cup-#{$i}.avif') !important;
            }
          }
        }
      }
    }

    .right-side {
      &-header {
        height: 4.375rem;
        margin-bottom: 1.25rem;

        .selectbox-periods {
          position: absolute;
          left: 50%;
          transform: translate(-50%) $skewX-value !important;
        }
      }

      :deep(.player-position-position.is-rank-1) {
        @if $isWsm {
          background-image: linear-gradient(to top, #ffef84, #f6c717);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #daab36, #ffdf91);
        }
      }

      :deep(.player-position-position.is-rank-2) {
        @if $isWsm {
          background-image: linear-gradient(to top, #a4a4a4, #fff);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
        }
      }

      :deep(.player-position-position.is-rank-3) {
        @if $isWsm {
          background-image: linear-gradient(to top, #e77447, #ffb99e);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #f58d57, #ffb28a);
        }
      }
    }
  }
}
</style>
